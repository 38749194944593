<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <!--begin::Detail DetailPatrol-->
    <div class="row">
      <div class="col-xxl-2 col-12 col-md-3 pt-0 pb-0">
        <div class="card gutter-b">
          <b-button variant="primary" class="w-100" @click="exportExcell"
            >Export to Excel</b-button
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-12 col-12 col-md-12">
        <b-card>
          <div class="row justify-content-between">
            <div class="col-md-1">
              <b-form-group class="mb-0">
                <b-form-select
                  id="per-page-select"
                  v-model="refPerpage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group class="mb-0">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <i class="menu-icon flaticon-search"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="params.query"
                    placeholder="Search"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="table-responsive">
            <b-table
              hover
              :items="listTable"
              :fields="columns"
              :per-page="params.limit"
              :current-page="refCurrentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-empty
            >
              <template #cell(images)="row">
                <img
                  :src="row.item.images[0].image"
                  style="
                    max-width: 100px;
                    max-height: 100px;
                    object-fit: scale-down;
                    cursor: pointer;
                  "
                  @click="previewImage(row.item.images[0].image)"
                />
              </template>
            </b-table>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-3">
              <b-pagination
                v-model="refCurrentPage"
                :total-rows="totalData"
                :per-page="params.limit"
                size="md"
                align="fill"
                class="ml-auto"
                style="padding: 0"
              ></b-pagination>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <b-modal
      size="lg"
      ref="modal-image-preview"
      hide-footer
      title="Preview Image"
    >
      <div style="display: flex; justify-content: center" id="imageid">
        <img
          :src="selectedImagePreview"
          class="ml-auto mr-auto"
          style="width: 100%"
        />
      </div>
      <!-- <b-button
        variant="primary"
        class="w-100"
        style="margin-top: 21px"
        @click="downloadPDF()"
        >Print</b-button
      > -->
    </b-modal>
    <!--end::DetailPatrol-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { debounce } from "debounce";
export default {
  name: "DetailPatrol",
  data() {
    return {
      filterPeriod: [
        { value: null, text: "Filter Periode" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      filterCustomer: [
        { value: null, text: "Filter Customer" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      selected: null,
      isLoading: false,
      // Table
      rowStyleOption: {
        stripe: true,
      },
      search: "",
      pageIndex: 1,
      totalData: 10,
      currentPage: 1,
      pageSize: 10,
      perPage: 10,
      pageOptions: [10, 15, { value: 100, text: "Show a lot" }],
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      columns: [
        {
          key: "employee.name",
          label: "Petugas",
          align: "left",
          sortBy: "",
        },
        {
          key: "employee.customer_branch.name",
          label: "Customer",
          align: "left",
          sortBy: "",
        },
        {
          key: "created_at",
          label: "Tanggal",
          align: "left",
          width: "",
          sortBy: "created_at",
          sortable: true,
        },
        {
          key: "scan_date",
          label: "Tanggal Scan",
          align: "left",
          width: "",
          sortBy: "scan_date",
          sortable: true,
        },
        {
          key: "sector.floor",
          label: "Lantai",
          align: "left",
          width: "",
          sortBy: "",
        },
        {
          key: "sector.sector",
          label: "Sektor",
          align: "left",
          width: "",
          sortBy: "",
        },
        {
          key: "shift",
          label: "Shift",
          align: "left",
          width: "",
          sortBy: "",
        },
        {
          key: "sector.name",
          label: "Alamat",
          align: "left",
          width: "40%",
          sortBy: "",
        },
        {
          key: "images",
          label: "Image",
          align: "left",
          width: "",
          sortBy: "",
        },
      ],
      sortBy: "",
      sortDesc: false,
      listTable: [],
      filter: null,
      filterOn: [],
      params: {
        sort: "created_at",
        order: "asc",
        limit: 10,
        offset: 0,
      },
      selectedImagePreview: "",
    };
  },
  components: {},
  computed: {
    refCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.params.offset = (value - 1) * this.params.limit;
      },
    },

    refPerpage: {
      get() {
        return this.perPage;
      },
      set(value) {
        this.perPage = value;
        this.params.limit = value;
      },
    },

    refSortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.sortBy = value;
        this.params.sort = value;
      },
    },

    refSortDirectionChange: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.sortDesc = value;
        if (value) this.params.order = "desc";
        else this.params.order = "asc";
      },
    },

    sortOptions() {
      return this.columns
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getTabel();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Patroli", route: "/report/patrol" },
      { title: "Laporan Patroli" },
    ]);
  },
  methods: {
    sortChange(params) {
      Object.entries(params).forEach((item) => {
        if (item[1]) {
          // eslint-disable-next-line
          console.log(item[0], item[1]);
        }
      });
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    // page size change
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    },
    async getTabel() {
      this.isLoading = true;
      try {
        const { id, startDate, endDate } = this.$route.params;
        await this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/report/detail/patrol/${id}`,
            {
              params: {
                start_date: startDate,
                end_date: endDate,
                ...this.params,
              },
            }
          )
          .then(({ data }) => {
            this.listTable = data.list;
            this.totalData = data.total;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    processChange: debounce(function () {
      this.getTabel();
    }, 500),
    previewImage(url) {
      this.selectedImagePreview = url;
      this.$refs["modal-image-preview"].show();
    },
    async exportExcell() {
      const { id, startDate, endDate } = this.$route.params;
      await this.$axios
        .get(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/report/patrol/export`,
          {
            params: {
              start_date: startDate,
              end_date: endDate,
              customer_branch_id: id || null,
              limit: 100000,
            },
          }
        )
        .then(({ data }) => {
          window.location.href = data.file;
        })
        .catch(({ response }) => {
          this.$bvToast.toast(response.data.message || "Terjadi Kesalahan", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
  watch: {
    params: {
      handler() {
        this.processChange();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.processChange();
      },
      deep: true,
    },
  },
};
</script>
